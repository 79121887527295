const getRandomPastelColor = (name: any) =>{
  let input=name;
  if (typeof input === 'string') {
    input = name.charAt(0);
  }
  // Convert uppercase to lowercase
  if (typeof input === 'string' && input.match(/[A-Z]/)) {
    input = input.toLowerCase();
  }

  // Check if input is a lowercase alphabet character
  if (typeof input === 'string' && input.match(/[a-z]/)) {
    const alphabetIndex = input.charCodeAt(0) - 97; // ASCII code for 'a' is 97
    const hue = (alphabetIndex * 20) % 360; // Generate hue value based on alphabet index
    return `hsl(${hue}, 70%, 80%)`; // Return pastel color in HSL format
  }

  // Check if input is a number
  if (typeof input === 'number' && !isNaN(input)) {
    const defaultHue = 120; // Default hue value for numbers
    return `hsl(${defaultHue}, 70%, 80%)`; // Return pastel color in HSL format
  }

  // Return null for invalid inputs
  return `rgb(0,0,0)`;
}
export default getRandomPastelColor;