import React, {useEffect, useState} from "react";
import Image from "next/image";
import {useRouter} from "next/router";
import {COOKIES, getCookie} from "utils/cookies";
import userCircle from "assets/user-icon.svg";
import {userReducer} from "types";
import dynamic from "next/dynamic";
import {isStaff} from "../../../utils/auth";
import getRandomPastelColor from "../../../utils/getRandomPastelColor";
import handleLogout from "../../../handlers/logout";
import rnaAnalytics from 'services/analytics/rnaAnalytics';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsPageSectionName } from 'constants/analytics';

const LogoutAlert = dynamic(() => import('./LogoutAlert'), {
  loading: () => <div>Loading...</div>
});

const DropdownMenu = dynamic(() => import('./DropdownMenu'), {
  loading: () => <div>Loading...</div>
});

interface ProfileDropdownProps {
  user?: userReducer;
}

const ProfileDropdown = ({user}: ProfileDropdownProps) => {
  const shopifyStoresCount = user ? user?.shopifyStoreData?.shopifyStores?.length : 0;
  const userPhoneNumber = user ? user?.shopifyStoreData?.storeMobileNumber?.toString() : '';
  const atleastOneShopifyStoreConnected = !!user?.shopifyStoreData?.shopifyStores?.length;
  const router = useRouter();
  const [showDropdownMenu, setDropdownMenu] = useState(false);
  const [showLogoutAlert, setLogoutAlert] = useState(false);
  const profilePathname = "profile";
  const [staff, setStaff] = useState({name:'', email:'',color:""});

  useEffect(() => {
    if (isStaff()) {
      const staff = getCookie(null, COOKIES.staffConfig);
      if(staff){
        const parsedStaff = JSON.parse(staff);
        setStaff({name:parsedStaff.name,email:parsedStaff.email,color:getRandomPastelColor(parsedStaff.name)});
      }
    }
  },[]);

  useEffect(() => {
    if(showDropdownMenu) {
      rnaAnalytics.clickEvent({
        action: AnalyticsEventAction.HeaderProfileIcon,
        eventData: {
          [AnalyticsEvent.Extras]: {
            [AnalyticsEvent.PageSectionName]: AnalyticsPageSectionName.TopNavbar,
          }
        }
      }).send();
    }
  }, [showDropdownMenu])

  const logout = () => {
      handleLogout(router);
  };

  const handleLogoutAlertClose = () => {
    setLogoutAlert((prevState) => !prevState);
  };

  return (
    <div className="flex items-center smScreen:hidden">
      <div
        className="relative pl-[20px]"
        onMouseOver={() => setDropdownMenu(true)}
        onMouseLeave={() => setDropdownMenu(false)}
      >
        <button
          className="flex items-center cursor-pointer smScreen:hidden" 
          id="manage-account-button"
        >
          <Image
            src={userCircle}
            alt="Account"
            loading="eager"
          />
        </button>
        <DropdownMenu
          staff={staff}
          onLogoutClick={handleLogoutAlertClose}
          profilePathname={profilePathname}
          showOption={showDropdownMenu}
          shopifyStoresCount={shopifyStoresCount}
          userPhoneNumber={userPhoneNumber}
          atleastOneShopifyStoreConnected={atleastOneShopifyStoreConnected}
        />
      </div>
      <LogoutAlert
        isVisible={showLogoutAlert}
        onClose={handleLogoutAlertClose}
        onLogout={logout}
      />
    </div>
  );
};

export default ProfileDropdown;
